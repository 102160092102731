import React from 'react'

import logo_umbragroup from './../UI/logo-umbragroup.svg'
import logo_umbra3d from './../UI/logo-umbra3d.svg'
import logo_umbraint from './../UI/Logo_UI.svg'
import background from './../UI/background.svg'

import firma_carlos from './../UI/firma-carlos.svg'
import firma_diana from './../UI/firma-diana.svg'

interface Props{}

interface State{
    readMore:boolean
}

class Landing extends React.Component<Props,State>{
    constructor(props: Props){
        super(props);
        this.state = {
            readMore: false
        }
    }

    render(): React.ReactNode {
        return(
            <div className={this.state.readMore ? 'landing-overflow':'landing'}>
                <div className='header'>
                    <img src={logo_umbragroup}></img>
                </div>
                <img className='background' src={background}></img>
                <div className='landing-content'>
                    <div className='umbra-group-container'>
                        <img src={logo_umbragroup} />
                    </div>
                    <div className='other-logos-container'>
                        <a href='https://umbra3d.studio/' target={"_blank"} className="logo-3d"><img src={logo_umbra3d} ></img></a>
                        <a href='https://www.umbraint.com/' target={"_blank"} className="logo-int"><img src={logo_umbraint}/></a>
                    </div>
                    <div className='landing-text-first'>
                        <div className='title'>¡Hola!</div>
                        
                        <div className='text'>Desde el 2012 hemos trabajado para consolidar una compañía que ha desarrollado proyectos interactivos y experienciales de alta calidad que tienen en común el uso de nuevas tecnologías, el diseño y el desarrollo de software.</div>
                        
                        <div className='text'>¿Qué es <strong>Umbra-Group</strong>? Se trata de un conglomerado de marcas que nació el 1 de julio de 2022 y agrupa negocios que son sinónimo de excelencia, confianza, tecnología e innovación con sede en Bogotá, Colombia y con negocios en países como Perú, Chile,  México, España, Canadá y Estados Unidos.  </div>
                        
                        {this.state.readMore ? null:<div className='read-more' onClick={() => {this.setState({readMore: true})}}>Leer más.</div>}
                    </div>
                    {
                        this.state.readMore ?
                        <div className='landing-text-second'>
                            <div className='text'>Siempre hemos creído que con la tecnología no hay obstáculos para hacer realidad grandes ideas y  crear los proyectos del mañana. Sin embargo, esto no sería posible sin el trabajo de todos nuestros colaboradores, proveedores y clientes que siempre han confiado en la calidad de nuestros productos que generan valor.</div>
                            
                            <div className='text'>Gracias a un equipo multidisciplinario de más de 30 talentosos profesionales del cual hacen parte ingenieros, diseñadores, publicistas, arquitectos,  administradores, mercadólogos y técnicos; garantizamos el cumplimiento, la calidad y la creación de relaciones duraderas a largo plazo con más de 300 empresas con las que hemos trabajado, llegando a nuevos mercados a nivel internacional para dejar en alto el nombre de Colombia. </div>
                            
                            <div className='text'>Umbra-Group reemplazó a Umbra Experiencia Interactiva SAS como empresa legalmente constituida en Colombia y actualmente la integran las marcas <strong><a href='https://www.umbraint.com/' target={"_blank"}>Umbra Interactive</a> </strong>y <strong><a  href='https://umbra3d.studio/' target={"_blank"}>Umbra3D.studio</a></strong>. </div>
                            
                            <div className='text'>Umbra Interactive es una agencia de marketing experiencial que desde 2014 se dedica al diseño y desarrollo de activaciones de marca, herramientas de venta y simuladores de entrenamiento con el uso de nuevas tecnologías.</div>
                            
                            <div className='text'>En 2019, creamos Umbra3D.studio, un estudio de comunicación arquitectónica enfocado en el desarrollo de software interactivo, soluciones Cloud y visualización hiperrealista que mejoran los procesos de venta, conectando al sector real estate y construcción con sus clientes.</div>
                            
                            <div className='text'>Umbra-Group representa las ideas que impulsaran los grandes negocios del mañana que serán independientes y diferentes en el desarrollo de su marca, como principio de un nuevo capítulo de muchas emociones y sentimientos en cada uno de los participantes de la familia Umbra.</div>
                            
                            <div className='text'>Sean todos bienvenidos y que esta historia continúe…</div>
                            <div className='signature-container'>
                                <div className='carlos-signature'>
                                    <div className='divisor'/>
                                    <div className='name'>Carlos Duque</div>
                                    <div className='position'>CEO & Co-Fundador</div>
                                </div>
                                <div className='diana-signature'>
                                    <div className='divisor'/>
                                    <div className='name'>Diana Rodríguez</div>
                                    <div className='position'>CMO & Co-Fundador</div>
                                </div>
                            </div>
                        </div>:null
                    }
                    {
                        this.state.readMore ?
                        <div className='footer'>
                            <div className='copyright'>©2022 Umbra-Group. All rights reserved.</div>
                            <img src={logo_umbragroup}/>
                        </div>:null
                    }
                </div>
            </div>
        )
    }
}

export default Landing