import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Landing from './components/landing.component';

function App() {
  return (
    <BrowserRouter>
      <div className='main-landing'>
        <Routes>
          <Route path={"/"} element={<Landing></Landing>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
